import { CircularProgress, Typography } from '@mui/material'
import { createSelector } from '@reduxjs/toolkit'
import { useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { Trans, useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { ensureError, localizeError } from '../../helpers'
import {
  clientApi,
  JobReadPublic,
  JobStatus,
  useAcceptJobApiV1JobsJobIdAcceptPutMutation,
  useDownloadJobApiV1JobsJobIdUploadGetMutation,
  useGetDeviceWithStatusGetMutation,
  useMoveJobOfflineApiV1JobsJobIdOfflinePutMutation,
  usePromoteAJobCandidateMutation,
  useRequeuePartsInJobMutation,
} from '../../store/clientApi'
import { selectDetailsItem } from '../../store/slices/detailsDrawerSlice'
import {
  invalidate,
  selectCheckedIds,
  selectRtkData,
} from '../../store/slices/tableSlice'
import { RootState, store } from '../../store/store'
import { Button } from '../Button/Button'
import { DialogBox } from '../DialogBox'
import {
  CloseSvg,
  DoneSvg,
  DownloadSvg,
  ReQueueSvg,
  SendToMachineSvg,
} from '../Icon/Icon'
import { JOB_TABLE_STATE_NAME } from '../JobsList/JobsList'

const useSelectedJobs = <T,>({
  inDrawer,
  isActionable,
  transform,
}: {
  inDrawer: boolean
  isActionable: (job: JobReadPublic) => boolean
  transform?: (job: JobReadPublic) => T
}) => {
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const checkedIds =
    useSelector((state: RootState) => {
      if (inDrawer) {
        const items = selectDetailsItem(state, basePath, selectedTab)
        return items?.itemType === 'Job' && items.selectedIds.length === 1
          ? items.selectedIds
          : []
      } else {
        return selectCheckedIds(state, JOB_TABLE_STATE_NAME)
      }
    }) ?? []

  const canAction = useSelector((state: RootState) => {
    if (inDrawer) {
      const items = selectDetailsItem(state, basePath, selectedTab)
      const job =
        items?.itemType === 'Job' && items.selectedIds.length === 1
          ? clientApi.endpoints.getJobApiV1JobsJobIdGet.select({
              jobId: Number(items.selectedIds[0]),
            })(state)?.data
          : undefined
      if (job === undefined) return false

      return isActionable(job)
    } else {
      return selectSelectedJobs(state).every((job) => isActionable(job))
    }
  })

  const selectFromJobs = useMemo(() => {
    const emptyArray: JobReadPublic[] = []
    return createSelector(
      [
        ({ table }: { table?: JobReadPublic[] }) => table ?? emptyArray,
        ({ drawer }: { drawer?: JobReadPublic }) => drawer,
      ],
      (table, drawer) =>
        transform
          ? (drawer ? [drawer] : table).map((job) => transform(job))
          : undefined,
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [transform])

  const transformResults = useSelector((state: RootState) => {
    if (transform === undefined) return undefined

    if (inDrawer) {
      const items = selectDetailsItem(state, basePath, selectedTab)
      const job =
        items?.itemType === 'Job' && items.selectedIds.length === 1
          ? clientApi.endpoints.getJobApiV1JobsJobIdGet.select({
              jobId: Number(items.selectedIds[0]),
            })(state)?.data
          : undefined
      return selectFromJobs({ drawer: job })
    } else {
      return selectFromJobs({ table: selectSelectedJobs(state) })
    }
  })

  return {
    checkedIds,
    canAction,
    transformResults,
  }
}

const invalidateTable = () => {
  store.dispatch(invalidate(JOB_TABLE_STATE_NAME))
}

const emptyArray: JobReadPublic[] = []

const selectSelectedJobs = createSelector(
  [
    (state: RootState): JobReadPublic[] =>
      selectRtkData(
        state,
        JOB_TABLE_STATE_NAME,
        clientApi.endpoints.getJobsApiV1JobsGet.select,
      )?.data?.content ?? emptyArray,
    (state: RootState) => selectCheckedIds(state, JOB_TABLE_STATE_NAME),
  ],
  (content, checkedIds) => {
    return checkedIds
      ? (checkedIds
          .map((index) => content.find((job) => job.id.toString() === index))
          .filter((job) => job !== undefined) as JobReadPublic[])
      : emptyArray
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  },
)

const MoveOfflineButton = ({ inDrawer }: { inDrawer?: boolean }) => {
  const { t } = useTranslation('jobs')
  const [rejectOpen, setRejectOpen] = useState(false)
  const [offline] = useMoveJobOfflineApiV1JobsJobIdOfflinePutMutation()

  const { checkedIds, canAction: canMoveOffline } = useSelectedJobs({
    inDrawer: inDrawer ?? false,
    isActionable: (job) => job.status === JobStatus.TO_ACCEPT,
  })

  const moveOffline = async () => {
    const loadingId = toast.loading(t('common:label.preparingDownload'))
    invalidateTable()
    let successCount = 0

    // Create an array of promises for all jobs
    const promises = checkedIds.map(async (job) => {
      try {
        const download = await offline({ jobId: Number(job) }).unwrap()

        const link = document.createElement('a')
        link.href = download.presigned_url
        link.download = `${job}.zip`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        toast.custom(
          <Trans
            t={t}
            i18nKey={'message.reworkOffline'}
            components={{
              bold: <strong style={{ fontWeight: 'bold' }} />,
            }}
            values={{
              id: job,
            }}
          />,
          {
            icon: <DownloadSvg color="inherit" />,
          },
        )
        successCount++
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
    })

    // Wait for all promises to settle (fulfilled or rejected)
    await Promise.allSettled(promises)

    toast.dismiss(loadingId)

    if (successCount > 0) {
      toast.success(
        t('common:label.downloadJobsSuccess', { count: successCount }),
      )
    }
  }

  return canMoveOffline ? (
    <>
      <Button
        color="error"
        startIcon={<CloseSvg color="inherit" />}
        onClick={() => setRejectOpen(true)}
        short={inDrawer}
      >
        {t('button.moveOffline')}
      </Button>
      <DialogBox
        title={t('title.moveOffline')}
        message={
          <>
            <Typography>
              {t('message.moveOffline', { count: checkedIds.length })}
            </Typography>
          </>
        }
        open={rejectOpen}
        setOpen={setRejectOpen}
        args={undefined}
        onClose={(approve) => {
          if (approve) moveOffline()
        }}
        confirmText={t('button.download')}
        confirmColor={'error'}
        declineText={t('button.cancel')}
        declineColor={'secondary'}
        ignoreClosed
      ></DialogBox>
    </>
  ) : null
}

const AcceptButton = ({ inDrawer }: { inDrawer?: boolean }) => {
  const { t } = useTranslation('jobs')
  const [accept] = useAcceptJobApiV1JobsJobIdAcceptPutMutation()
  const [getDevice] = useGetDeviceWithStatusGetMutation()
  const {
    checkedIds,
    canAction: canAccept,
    transformResults,
  } = useSelectedJobs({
    inDrawer: inDrawer ?? false,
    isActionable: (job) => job.status === JobStatus.TO_ACCEPT,
    transform: (job) => job.printer_serial,
  })
  const devices = transformResults ?? []

  const acceptJobs = async () => {
    const loadingId = toast.loading(t('common:label.acceptingJobs'))
    invalidateTable()
    let successCount = 0

    // Create an array of promises for all jobs
    const promises = checkedIds.map(async (job, i) => {
      let device: string | undefined = undefined

      try {
        device =
          (await getDevice({ serialNumber: devices[i] }).unwrap()).status
            ?.name ?? undefined
      } catch {
        //
      }

      toast.custom(
        <Trans
          t={t}
          i18nKey={
            'message.submittingJob' + (device !== undefined ? '_device' : '')
          }
          components={{
            bold: <strong style={{ fontWeight: 'bold' }} />,
          }}
          values={{
            device: device,
            id: job,
          }}
        />,
        {
          icon: <SendToMachineSvg color="inherit" />,
        },
      )

      try {
        await accept({ jobId: Number(job) }).unwrap()
        successCount++
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
    })

    // Wait for all promises to settle (fulfilled or rejected)
    await Promise.allSettled(promises)
    toast.dismiss(loadingId)

    if (successCount > 0) {
      toast.success(
        t('common:label.acceptedJobsSuccess', { count: successCount }),
      )
    }
  }

  return canAccept ? (
    <Button
      startIcon={<DoneSvg color="inherit" />}
      onClick={acceptJobs}
      short={inDrawer}
    >
      {t('common:button.accept')}
    </Button>
  ) : null
}

const PromoteButton = ({ inDrawer }: { inDrawer?: boolean }) => {
  const { t } = useTranslation('jobs')
  const [promote] = usePromoteAJobCandidateMutation()

  const { checkedIds, canAction: canPromote } = useSelectedJobs({
    inDrawer: inDrawer ?? false,
    isActionable: (job) => job.status === JobStatus.CANDIDATE,
  })

  const promoteJobs = async () => {
    const loadingId = toast.loading(t('common:label.promotingJobs'))
    invalidateTable()
    let successCount = 0

    // Create an array of promises for all jobs
    const promises = checkedIds.map(async (job, i) => {
      try {
        await promote({ jobId: Number(job) }).unwrap()
        successCount++
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
    })

    // Wait for all promises to settle (fulfilled or rejected)
    await Promise.allSettled(promises)
    toast.dismiss(loadingId)

    if (successCount > 0) {
      toast.success(
        t('common:label.promotedJobsSuccess', { count: successCount }),
      )
    }
  }

  return canPromote ? (
    <Button
      startIcon={<DoneSvg color="inherit" />}
      onClick={promoteJobs}
      short={inDrawer}
    >
      {t('button.promoteJob')}
    </Button>
  ) : null
}

const RequeuePartsButton = ({ inDrawer }: { inDrawer?: boolean }) => {
  const { t } = useTranslation('jobs')
  const [requeue] = useRequeuePartsInJobMutation()
  const [requeueOpen, setRequeueOpen] = useState(false)

  const { checkedIds, canAction: canRequeue } = useSelectedJobs({
    inDrawer: inDrawer ?? false,
    isActionable: (job) =>
      job.status === JobStatus.TO_ACCEPT || job.status === JobStatus.FAILED,
  })

  const requeueParts = async () => {
    const loadingId = toast.loading(t('label.requeuingParts'))
    invalidateTable()
    let successCount = 0

    // Create an array of promises for all jobs
    const promises = checkedIds.map(async (job) => {
      try {
        await requeue({ jobId: Number(job) }).unwrap()
        successCount++
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
    })

    // Wait for all promises to settle (fulfilled or rejected)
    await Promise.allSettled(promises)
    toast.dismiss(loadingId)

    if (successCount > 0) {
      toast.success(t('label.requeuedPartsSuccess', { count: successCount }))
    }
  }

  return canRequeue ? (
    <>
      <Button
        startIcon={<ReQueueSvg color="inherit" />}
        onClick={() => setRequeueOpen(true)}
        short={inDrawer}
      >
        {t('button.requeueParts')}
      </Button>
      <DialogBox
        title={t('title.requeueParts')}
        message={
          <>
            <Typography>{t('message.requeueParts')}</Typography>
          </>
        }
        open={requeueOpen}
        setOpen={setRequeueOpen}
        args={undefined}
        onClose={(approve) => {
          if (approve) requeueParts()
        }}
        confirmText={t('button.requeueParts')}
        confirmColor={'primary'}
        declineText={t('common:button.cancel')}
        declineColor={'secondary'}
        ignoreClosed
      ></DialogBox>
    </>
  ) : null
}

const DownloadButton = ({ inDrawer }: { inDrawer?: boolean }) => {
  const { t } = useTranslation('jobs')
  const [download] = useDownloadJobApiV1JobsJobIdUploadGetMutation()

  const { checkedIds, canAction: canDownload } = useSelectedJobs({
    inDrawer: inDrawer ?? false,
    isActionable: (job) => job.status === JobStatus.TO_ACCEPT,
  })

  const downloadJobs = async () => {
    let successCount = 0
    const loadingId = toast.loading(t('common:label.preparingDownload'))
    invalidateTable()

    // Create an array of promises for each job download
    const promises = checkedIds.map(async (job) => {
      try {
        const downloadUrl = await download({ jobId: parseInt(job) }).unwrap()

        const link = document.createElement('a')
        link.href = downloadUrl.presigned_url
        link.download = `${job}.zip`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        successCount++
      } catch (err) {
        const error = ensureError(err)
        toast.error(localizeError(t, error))
      }
    })

    // Wait for all promises to settle (fulfilled or rejected)
    await Promise.allSettled(promises)

    // Dismiss the loading toast once all promises are settled
    toast.dismiss(loadingId)

    if (successCount > 0) {
      toast.success(
        t('common:label.downloadJobsSuccess', { count: successCount }),
      )
    }
  }

  return canDownload ? (
    <Button
      startIcon={<DownloadSvg color="inherit" />}
      onClick={() => downloadJobs()}
      disabled={!canDownload}
      short={inDrawer}
    >
      {t('button.download')}
    </Button>
  ) : null
}

export const Edit = ({ inDrawer }: { inDrawer?: boolean }) => {
  const error = useSelector(
    (state: RootState) =>
      selectRtkData(
        state,
        JOB_TABLE_STATE_NAME,
        clientApi.endpoints.getJobsApiV1JobsGet.select,
      )?.error,
  )
  const isLoading = useSelector((state: RootState) => {
    const query = selectRtkData(
      state,
      JOB_TABLE_STATE_NAME,
      clientApi.endpoints.getJobsApiV1JobsGet.select,
    )

    return query?.isLoading && query?.isUninitialized
  })

  return error ? (
    <>{null}</>
  ) : isLoading ? (
    <CircularProgress />
  ) : (
    <>
      <MoveOfflineButton inDrawer={inDrawer} />
      <RequeuePartsButton inDrawer={inDrawer} />
      <AcceptButton inDrawer={inDrawer} />
      <DownloadButton inDrawer={inDrawer} />
      <PromoteButton inDrawer={inDrawer} />
    </>
  )
}
