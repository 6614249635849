import { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { InfoCard, ProgressCard } from '../../../components/Cards/Cards'
import { deviceStateToColor } from '../../../helpers'
import {
  clientApi,
  JobStatus,
  useGetJobsByDeviceQuery,
} from '../../../store/clientApi'
import {
  selectDetailsItemId,
  setDetailsItem,
  setDrawerOpen,
} from '../../../store/slices/detailsDrawerSlice'
import {
  selectRtkData,
  setTableChecked,
} from '../../../store/slices/tableSlice'
import { setTimelineDuration } from '../../../store/slices/timelineDurationSlice'
import { RootState, store } from '../../../store/store'
import { deviceMessage } from '../../../utils/getDeviceMessage'
import { humanizeDurationHoursMinutes } from '../../../utils/shortHumanizeDuration'
import { GetTimelineJobsQuery, TIMELINE_TABLE_STATE_NAME } from '../Timeline'

interface TimelineRowProps {
  deviceName: string
  timelineHours: number
  timelineHoursWidth: number
}

export const TimelineRow: FC<TimelineRowProps> = ({
  deviceName,
  timelineHours,
  timelineHoursWidth,
}) => {
  const { t } = useTranslation('devices')
  const UNAVAILABLE = t('common:loading.unavailable')
  const { id: selectedTab } = useParams()
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]

  const currDevice = useSelector((state: RootState) =>
    selectRtkData(
      state,
      TIMELINE_TABLE_STATE_NAME,
      clientApi.endpoints.getDevicesWithStatusApiV1DevicesStatusGet.select,
    )?.data?.content?.find((device) => device.thing_name === deviceName),
  )

  const { jobs } = useGetJobsByDeviceQuery(GetTimelineJobsQuery(), {
    selectFromResult: ({ data }) => ({ jobs: data?.byDevice?.[deviceName] }),
  })
  const selectedDetailsItemId = useSelector((state: RootState) =>
    selectDetailsItemId(state, basePath, selectedTab),
  )

  const maxTimelineHours = useSelector(
    (state: RootState) => state.timelineDurationSlice.hours,
  )
  const currentTime = new Date(Date.now()).setSeconds(0, 0) // update once per minute
  // Calculate the floored hour which is 2 hours before the current time
  const flooredHourTime = new Date(currentTime - 1000 * 60 * 60 * 2).setMinutes(
    0,
    0,
    0,
  )

  // Subtract 20 minutes from the floored hour time
  const timelineStartTime = new Date(flooredHourTime - 1000 * 60 * 20)

  // Memoize queued_build_times & queued_build_names
  const allQueuedBuildNames = useMemo(() => {
    return ((currDevice?.status?.queued_build_names ?? []) as string[]).concat(
      jobs?.map((job) => `job-${job.id}`) ?? [],
    )
  }, [currDevice, jobs])
  const allQueuedBuildTimes = useMemo(() => {
    return ((currDevice?.status?.queued_build_times ?? []) as number[]).concat(
      jobs?.map((job) => job.estimated_print_time_seconds) ?? [],
    )
  }, [currDevice, jobs])
  const elapsed = currDevice?.status?.elapsed ?? 0 // seconds
  const remaining = currDevice?.status?.remaining ?? 0 // seconds
  const printTime = elapsed + remaining
  const progress =
    printTime === 0 || currDevice?.status?.connected === false
      ? 0
      : (elapsed / printTime) * 100
  const state = currDevice?.status?.state ?? ''
  const printStartTime = currentTime - elapsed * 1000
  const currentBuildName = currDevice?.status?.current_build_name ?? UNAVAILABLE

  useEffect(() => {
    // update longest row (hours) if this is longer than previous printer rows
    const all_build_times_seconds = allQueuedBuildTimes.reduce(
      (partialSum, nextNum) => partialSum + nextNum,
      0,
    )

    // total hours calculation:
    // 2h 20m displayed before current time floored hour so maximum is 3hrs 19m (3.2)
    // 6m padding between print jobs (0.1 * jobs)
    // 20mins end padding (~0.3)
    // total: remaining build times + padding + padding_between_prints
    let totalHours = 0
    if (allQueuedBuildTimes.length === 0 && allQueuedBuildNames.length !== 0) {
      // queued-build-times not present. Fallback to 2 hours each
      totalHours = Math.ceil(
        remaining / 60 / 60 + allQueuedBuildNames.length * 2.1 + 3.5,
      )
    } else {
      totalHours = Math.ceil(
        remaining / 60 / 60 +
          all_build_times_seconds / 60 / 60 +
          allQueuedBuildTimes.length * 0.1 +
          3.5,
      )
    }
    if (maxTimelineHours < totalHours) {
      store.dispatch(setTimelineDuration(totalHours))
    }
  }, [maxTimelineHours, allQueuedBuildTimes, allQueuedBuildNames, remaining])

  if (elapsed === 0 && allQueuedBuildNames.length === 0) {
    return <></>
  }

  const hoursSinceTimelineStart =
    (printStartTime - timelineStartTime.valueOf()) / 1000 / 60 / 60
  const offsetX = (timelineHoursWidth / timelineHours) * hoursSinceTimelineStart
  const cardWidth =
    (timelineHoursWidth / timelineHours) * ((elapsed + remaining) / 60 / 60)
  const remainingWidth =
    (timelineHoursWidth / timelineHours) * (remaining / 60 / 60)

  const inProgress = ['Starting', 'Cancelling', 'Pausing', 'Printing'].includes(
    state,
  )

  const secondsToPixelWidth = (seconds: number): number => {
    const hourWidth = timelineHoursWidth / timelineHours
    const mins = seconds / 60
    const hours = mins / 60
    const width = hours * hourWidth
    return width
  }

  const pixelsToMillisecondsWidth = (width: number): number => {
    // 1hr = (timelineHoursWidth / timelineHours)px
    const hourWidth = timelineHoursWidth / timelineHours
    const hours = width / hourWidth
    const mins = hours * 60
    const secs = mins * 60
    return secs * 1000
  }

  const getQueuedJobPixelOffset = (index: number): string => {
    /** Return '{number}px' string offset for the queued build index */
    if (allQueuedBuildTimes[index] === undefined) {
      // missing queued build times, default to 2 hours
      return `${offsetX + cardWidth + index * ((timelineHoursWidth / timelineHours + 3) * 2) + 6}px`
    }
    const previous_build_times = allQueuedBuildTimes.slice(0, index)
    const previous_builds_total_duration = previous_build_times.reduce(
      (partialSum, nextNum) => partialSum + nextNum,
      0,
    )
    const previous_builds_total_width = secondsToPixelWidth(
      previous_builds_total_duration,
    )

    // add padding
    const padding = index * 6 + 6
    return `${previous_builds_total_width + padding + offsetX + cardWidth}px`
  }

  const getQueuedJobPixelWidth = (index: number): number => {
    /** Return number pixel width for the queued build index */
    if (allQueuedBuildTimes[index] === undefined) {
      // missing queued build times, default to 2 hours
      return (timelineHoursWidth / timelineHours) * 2
    }
    const mins = allQueuedBuildTimes[index] / 60
    const hours = mins / 60
    return (timelineHoursWidth / timelineHours) * hours
  }

  const handleOnClickJob = (build: string) => {
    let isFunnelJob = false
    let selectedId = build
    // get jobs for printer
    if (build.startsWith('job-')) {
      selectedId = build.substring(4)
      isFunnelJob = true
    }

    store.dispatch(
      setDetailsItem({
        page: basePath,
        tab: selectedTab,
        selectedId: isFunnelJob ? selectedId : `${deviceName} ${selectedId}`,
        itemType: isFunnelJob ? 'Job' : 'NonFunnelJob',
      }),
    )

    store.dispatch(setDrawerOpen({ value: true }))

    if (!currDevice || !currDevice?.thing_name) return
    store.dispatch(
      setTableChecked({
        name: TIMELINE_TABLE_STATE_NAME,
        checked: { [currDevice.thing_name]: false },
        lastChecked: undefined,
      }),
    )
  }

  const isBuildSelected = (buildName: string) => {
    let selectedJobId = selectedDetailsItemId
    let buildJobId = buildName
    if (buildName.startsWith('job-')) {
      buildJobId = buildName.substring(4)
    } else {
      const selectedDeviceAndJobArray = selectedDetailsItemId?.split(/ (.*)/s)
      selectedJobId = selectedDeviceAndJobArray?.[1] ?? undefined
    }

    return selectedJobId === buildJobId
  }

  return (
    <>
      <ProgressCard
        onClick={() => {
          handleOnClickJob(currentBuildName)
        }}
        selected={isBuildSelected(currentBuildName)}
        offsetX={`${offsetX}px`}
        width={`${cardWidth}px`}
        title={currentBuildName}
        height={'98px'}
        subtitle={
          inProgress
            ? t('devices:state.printing', {
                duration: humanizeDurationHoursMinutes(
                  pixelsToMillisecondsWidth(remainingWidth),
                ),
              })
            : deviceMessage(currDevice)
        }
        value={progress}
        color={deviceStateToColor(currDevice)}
        offsetLeftToAvoidIntersection
      />
      {currDevice?.status?.queued_build_names?.length > 0 &&
        (currDevice?.status?.queued_build_names as string[]).map(
          (build, index) => (
            <InfoCard
              onClick={() => {
                handleOnClickJob(build)
              }}
              selected={isBuildSelected(build)}
              key={index}
              offsetX={getQueuedJobPixelOffset(index)}
              width={`${getQueuedJobPixelWidth(index)}px`}
              title={build}
              height={'98px'}
              subtitle={humanizeDurationHoursMinutes(
                allQueuedBuildTimes[index] !== undefined
                  ? allQueuedBuildTimes[index] * 1000
                  : pixelsToMillisecondsWidth(
                      (timelineHoursWidth / timelineHours) * 2,
                    ),
              )}
            />
          ),
        )}

      {jobs !== undefined &&
        jobs.length > 0 &&
        jobs.map((job, index) => {
          index += currDevice?.status?.queued_build_names?.length ?? 0
          const build = `job-${job.id}` //eslint-disable-line
          return (
            <InfoCard
              dashed={[
                JobStatus.CREATED,
                JobStatus.CANDIDATE,
                JobStatus.PROCESSING,
                JobStatus.TO_ACCEPT,
              ].includes(job.status)}
              onClick={() => {
                handleOnClickJob(build)
              }}
              selected={isBuildSelected(build)}
              key={index}
              offsetX={getQueuedJobPixelOffset(index)}
              width={`${getQueuedJobPixelWidth(index)}px`}
              title={build}
              height={'98px'}
              subtitle={humanizeDurationHoursMinutes(
                job.estimated_print_time_seconds * 1000,
              )}
            />
          )
        })}
    </>
  )
}
