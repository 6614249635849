/* eslint i18next/no-literal-string: 0 */
import FlutterDashOutlinedIcon from '@mui/icons-material/FlutterDashOutlined'
import { Box } from '@mui/material'
import { FC, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { Button } from '../../components/Button/Button'
import { IconCard, InfoCard, ProgressCard } from '../../components/Cards/Cards'
import { Dashboard, DashboardLink } from '../../components/Dashboard/Dashboard'
import { JobStatusIndicator } from '../../components/JobStatus/JobStatus'
import { PartProgressStatus } from '../../components/PartStatus/PartStatus'
import { StatusBanner } from '../../components/StatusBanner/StatusBanner'
import { StatusIcon } from '../../components/StatusIcon/StatusIcon'
import { colorToIcon } from '../../helpers'
import { JobStatus } from '../../store/clientApi'

interface TestProps {
  primaryLinks: DashboardLink[]
  footerLinks: DashboardLink[]
}

const Test: FC<TestProps> = (props) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(false)

  return (
    <Dashboard
      primaryLinks={props.primaryLinks}
      footerLinks={props.footerLinks}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          gap: '1em',
        }}
      >
        <br />
        <InfoCard
          title="Bay"
          subtitle="Ultra"
          info="Some information to display"
          selected={selected}
          onClick={() => setSelected(!selected)}
        />
        <br />
        <ProgressCard
          title="Bay"
          subtitle="Ultra"
          value={50}
          message="2 h 31 min remaining"
          onClose={() => {
            return
          }}
        />
        <ProgressCard
          title="Bay"
          subtitle="Ultra"
          value={50}
          inprogress={true}
          message="2 h 31 min remaining"
          onClose={() => {
            return
          }}
        />
        <IconCard
          title="Dentbird"
          subtitle="AI powered dental design"
          icon={<FlutterDashOutlinedIcon />}
          width="200px"
        />
        <StatusBanner
          variant="info"
          message="Info message"
          action={{ name: 'Action', onClick: () => alert('Alert') }}
          open={true}
          value={50}
        />
        <StatusBanner variant="success" message="Success message" open={true} />
        <StatusBanner
          variant="success"
          message="Inprogress Success message"
          open={true}
          inprogress={true}
          closable={false}
        />
        <StatusBanner variant="warning" message="Warning message" open={true} />
        <StatusBanner
          variant="error"
          message="Error message"
          open={true}
          closable={false}
        />
        <br />
        <br />
        <StatusIcon
          variant="error"
          message="error"
          icon={colorToIcon('error')}
        />
        <br />
        <StatusIcon variant="info" message="info" icon={colorToIcon('info')} />
        <br />
        <StatusIcon
          variant="success"
          message="success"
          icon={colorToIcon('success')}
        />
        <br />
        <StatusIcon
          variant="warning"
          message="warning"
          icon={colorToIcon('warning')}
        />
        <Button variant="contained" onClick={() => toast.success('success')}>
          {t('toast success')}
        </Button>
        <Button variant="contained" onClick={() => toast.loading('loading')}>
          {t('toast loading')}
        </Button>
        <Button variant="contained">{t('contained')}</Button>
        <Button variant="contained" disabled>
          {t('contained-disabled')}
        </Button>
        <Button color="secondary" variant="contained">
          {t('contained-secondary')}
        </Button>
        <Button color="secondary" variant="contained" disabled>
          {t('contained-disabled-secondary')}
        </Button>
        <Button color="error" variant="contained">
          {t('contained-error')}
        </Button>
        <Button color="error" variant="contained" disabled>
          {t('contained-disabled-error')}
        </Button>

        <Button variant="outlined">{t('outlined')}</Button>
        <Button variant="outlined" disabled>
          {t('outlined-disabled')}
        </Button>
        <Button color="secondary" variant="outlined">
          {t('outlined-secondary')}
        </Button>
        <Button color="secondary" variant="outlined" disabled>
          {t('outlined-disabled-secondary')}
        </Button>
        <Button color="error" variant="outlined">
          {t('outlined-error')}
        </Button>
        <Button color="error" variant="outlined" disabled>
          {t('outlined-disabled-error')}
        </Button>

        <Button variant="text">{t('text')}</Button>
        <Button variant="text" disabled>
          {t('text-disabled')}
        </Button>
        <Button color="secondary" variant="text">
          {t('text-secondary')}
        </Button>
        <Button color="secondary" variant="text" disabled>
          {t('text-disabled-secondary')}
        </Button>
        <Button color="error" variant="text">
          {t('text-error')}
        </Button>
        <Button color="error" variant="text" disabled>
          {t('text-disabled-error')}
        </Button>
        <JobStatusIndicator jobStatus={JobStatus.CREATED} />
        <JobStatusIndicator jobStatus={JobStatus.FAILED} />
        <JobStatusIndicator jobStatus={JobStatus.OFFLINE} />
        <JobStatusIndicator jobStatus={JobStatus.CANDIDATE} />
        <JobStatusIndicator jobStatus={JobStatus.PREPARING_TO_SEND} />
        <JobStatusIndicator jobStatus={JobStatus.PRINTED} />
        <JobStatusIndicator jobStatus={JobStatus.PRINTING} />
        <JobStatusIndicator jobStatus={JobStatus.PRINT_PENDING} />
        <JobStatusIndicator jobStatus={JobStatus.PROCESSING} />
        <JobStatusIndicator jobStatus={JobStatus.REJECTED} />
        <JobStatusIndicator jobStatus={JobStatus.TO_ACCEPT} />

        <Box
          component="div"
          sx={{
            width: '100%',
            borderTop: '1px solid #000000',
            display: 'flex',
            flexDirection: 'column',
            gap: '1em',
            alignItems: 'center',
            paddingY: '1em',
          }}
        >
          <PartProgressStatus status={0} />
          <PartProgressStatus status={1} />
          <PartProgressStatus status={2} />
          <PartProgressStatus status={3} />
          <PartProgressStatus status={4} />
          <PartProgressStatus status={5} />
          <PartProgressStatus status={6} />
          <PartProgressStatus status={7} />
          <PartProgressStatus status={8} />
          <PartProgressStatus status={9} />
          <PartProgressStatus status={10} />
          <PartProgressStatus status={11} />
          <PartProgressStatus status={12} />
          <PartProgressStatus status={13} />
          <PartProgressStatus status={14} />
          <PartProgressStatus status={15} />
          <PartProgressStatus status={16} />
          <PartProgressStatus status={17} />
          <PartProgressStatus status={18} />
          <PartProgressStatus status={19} />
        </Box>
      </Box>
    </Dashboard>
  )
}

export { Test }
