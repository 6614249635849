import {
  Box,
  Icon,
  IconButton,
  Paper,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'

import { createSelector } from '@reduxjs/toolkit'
import React, { FC, useEffect, useMemo, useState } from 'react'
import './ProgressBottomDrawer.css'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ProgressDrawerRow } from '../'
import {
  clearUploads,
  compareUpload,
  handleCancelUploads,
  PartUpload,
  UploadStatus,
} from '../../store/slices/partUploadsSlice'
import { AppDispatch, RootState } from '../../store/store'
import { DialogBox } from '../DialogBox'
import { CloseSvg, ExpandedSvg } from '../Icon/Icon'

export const ProgressBottomDrawer: FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation('inbox')
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(true)
  const [cancelAllModalOpen, setCancelAllModalOpen] = useState(false)
  const selectUploads = useMemo(() => {
    return createSelector(
      [(state: RootState) => state.uploads],
      (content) =>
        Object.values(content)
          .sort((a, b) => compareUpload(a as PartUpload, b as PartUpload))
          .map((upload) => (upload as PartUpload).uploadId),
      {
        memoizeOptions: {
          resultEqualityCheck: shallowEqual,
        },
      },
    )
  }, [])
  const isComplete = useSelector((state: RootState) =>
    Object.values(state.uploads as { [index: number]: PartUpload }).some(
      (upload) => upload.status !== UploadStatus.Complete,
    ),
  )
  const isInProgress = useSelector((state: RootState) =>
    Object.values(state.uploads as { [index: number]: PartUpload }).some(
      (upload) => upload.status !== UploadStatus.Complete || upload.cancelled,
    ),
  )
  const uploads = useSelector((state: RootState) => selectUploads(state))
  const [uploadsCount, setUploadsCount] = useState<number>(0)

  const isDownloading = useSelector(
    (state: RootState) => state.downloadingParts.isDownloading,
  )

  // Warn user before closing/refreshing browser tab when parts are uploading
  // But do not warn if the user is trying to download parts instead
  // of refresh / navigate
  useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      if (uploads.length > 0 && isComplete && isDownloading === false) {
        // There are incomplete uploads, so show the warning dialogue
        event.preventDefault()
        event.returnValue = ''
        return ''
      }
      // No in-progress uploads, so no need to show the warning dialogue
      event.returnValue = false
      return false
    }

    if (uploads.length > 0 && isComplete && isDownloading === false) {
      window.addEventListener('beforeunload', unloadCallback)
    } else {
      window.removeEventListener('beforeunload', unloadCallback)
    }

    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [uploads.length, isComplete, isDownloading])

  useEffect(() => {
    // Get the number of parts in uploads

    // Expand drawer whenever a new part is uploaded
    if (uploads.length > uploadsCount) {
      setIsOpen(true)
    }

    // Update the count of uploads
    setUploadsCount(uploads.length)
  }, [uploads.length, uploadsCount])

  const handleCloseButton = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation() // Prevent double-firing of overlapping buttons

    // Check if any parts are still uploading
    if (isInProgress) {
      setCancelAllModalOpen(true)
    } else {
      dispatch(clearUploads())
    }
  }
  const handleCancelAllUploads = () => {
    // Bulk cancel all uploads
    handleCancelUploads()
    //dispatch(handleCancelAndClearAllUploads(uploads))
  }

  const handleToggleDrawer = (e: React.SyntheticEvent) => {
    e.preventDefault()
    e.stopPropagation() // Prevent double-firing of overlapping buttons
    setIsOpen((prevState) => !prevState)
  }
  const uploadsLength = Object.keys(uploads).length

  if (uploadsLength > 0) {
    return (
      <>
        <Paper
          elevation={2}
          sx={{
            bgColor: theme.palette.background.paper,
            position: 'fixed',
            right: '24px',
            bottom: '0px',
            zIndex: '7',
            width: '350px',
            maxHeight: '300px',
            borderRadius: '6px 6px 0px 0px !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '0',
            padding: '0',
            border: (theme: Theme) => `1px solid ${theme.outline.main}`,
            ...(!isOpen && { maxHeight: '42px' }),
          }}
        >
          <Box
            component="div"
            sx={{
              width: '100%',
              padding: '8px 12px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              borderBottom: (theme: Theme) => `1px solid ${theme.outline.main}`,
              marginBottom: '-1px',
              zIndex: '1',
            }}
            onClick={(e: React.SyntheticEvent) => handleToggleDrawer(e)}
          >
            <Typography className={'progressRowTitleText'}>
              {t('title.uploads')}
            </Typography>
            <Box
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.5em',
              }}
            >
              <IconButton
                size="small"
                onClick={(e: React.SyntheticEvent) => handleToggleDrawer(e)}
              >
                <Icon
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ExpandedSvg
                    className={[
                      'collapseIcon',
                      !isOpen && 'collapseIconCollapsed',
                    ].join(' ')}
                  />
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                onClick={(e: React.SyntheticEvent) => handleCloseButton(e)}
              >
                <Icon
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CloseSvg />
                </Icon>
              </IconButton>
            </Box>
          </Box>
          <Box className="progressBottomDrawerContent" component="div">
            {Object.values(uploads).length > 0 &&
              Object.values(uploads).map((item, index) => {
                return <ProgressDrawerRow item={item} key={item} />
              })}
            <Box
              component="div"
              sx={{
                height: 'auto',
                flexGrow: '1',
                width: '100%',
                backgroundColor: theme.surface.low,
                borderTop: (theme: Theme) => `1px solid ${theme.outline.main}`,
              }}
            />
          </Box>
        </Paper>
        <DialogBox
          title={t('common:label.cancelUploadsModalTitle')}
          args={undefined}
          message={<Typography>{t('common:label.rejectModal')}</Typography>}
          open={cancelAllModalOpen}
          confirmColor="error"
          confirmText={t('common:label.cancelUploadsModalButtonPrimary')}
          declineText={t('common:label.cancelUploadModalButtonSecondary')}
          setOpen={setCancelAllModalOpen}
          onClose={(approve) => {
            if (approve) handleCancelAllUploads()
          }}
          sx={{ '.MuiPaper-root': { width: '100%', margin: '16px' } }}
        />
      </>
    )
  }
  return <></>
}
