import {
  Avatar,
  Box,
  Divider,
  LinearProgress,
  Link,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ensureError, localizeError } from '../../helpers'
import {
  IntegrationReadPublic,
  LinkReadPublic,
  useConnectIntegrationApiV1IntegrationsIntegrationIdLinkPutMutation,
  useGetAllIntegrationLinksApiV1IntegrationsLinksGetQuery,
  useGetIntegrationsApiV1IntegrationsGetQuery,
} from '../../store/clientApi'
import { setSearchApps } from '../../store/slices/appsSlice'
import { RootState, store } from '../../store/store'
import { Button } from '../Button/Button'
import { AddSvg } from '../Icon/Icon'
import { SearchField } from '../SearchField/SearchField'

const EMPTY_LINK_ARRAY: LinkReadPublic[] = []

const App = ({ client_id }: { client_id: string }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { data } = useGetIntegrationsApiV1IntegrationsGetQuery(
    {},
    {
      selectFromResult: ({ data, error, isLoading }) => ({
        data: (data?.content ?? EMPTY_INTEGRATION_ARRAY).find(
          (integration) => integration.client_id === client_id,
        ),
        error,
        isLoading,
      }),
    },
  )

  const { data: link } =
    useGetAllIntegrationLinksApiV1IntegrationsLinksGetQuery(
      {},
      {
        selectFromResult: ({ data, error, isLoading }) => ({
          data: (data?.content ?? EMPTY_LINK_ARRAY).find(
            (link) => link.client_id === client_id,
          ),
          error,
          isLoading,
        }),
      },
    )

  const [connect] =
    useConnectIntegrationApiV1IntegrationsIntegrationIdLinkPutMutation()

  const connectIntegration = async () => {
    try {
      const { authorize_url } = await connect({
        integrationId: client_id,
      }).unwrap()

      const link = document.createElement('a')
      link.href = authorize_url
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (err) {
      const error = ensureError(err)
      toast.error(localizeError(t, error))
    }
  }

  const halfSmDown = useMediaQuery(
    theme.breakpoints.down((theme.breakpoints.values['sm'] * 2) / 3),
  )
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const size = mdUp ? 160 : smDown ? 80 : 130

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        [theme.breakpoints.up('lg')]: {
          gap: '32px',
        },
        [theme.breakpoints.down('lg')]: {
          gap: '24px',
        },
        [theme.breakpoints.down('md')]: {
          gap: '16px',
        },
        [theme.breakpoints.down('sm')]: {
          gap: '15px',
        },
        flexShrink: 1,
        bgcolor: (theme) => theme.surface.normal,
        borderRadius: '0.5em',
        padding: '15px',
      }}
    >
      <Avatar
        alt={data?.name}
        variant="rounded"
        src={data?.logo_uri}
        sx={{
          width: `${size}px`,
          height: `${size}px`,
          '& > .MuiAvatar-img': {
            width: `${size}px`,
            height: `${size}px`,
            objectFit: 'contain',
          },
        }}
      ></Avatar>
      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: `${size}px`,
            flexGrow: 1,
          }}
        >
          <Link
            component="a"
            href={data?.homepage ?? '#'}
            underline="hover"
            target="_blank"
            onClick={() => {
              return
            }}
            sx={{
              fontWeight: (theme: Theme) => theme.typography.fontWeightBold,
              fontSize: '0.8rem',
            }}
          >
            {data?.name}
          </Link>
          <Typography
            variant={'body2'}
            sx={{
              flexShrink: 1,
              overflow: 'hidden',
              fontSize: '0.8rem',
              display: halfSmDown ? 'none' : 'block',
            }}
          >
            {data?.description}
          </Typography>
        </Box>
      </Box>
      <Box
        component="div"
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        {smDown ? (
          <Button
            startIcon={<AddSvg color="inherit" />}
            color="primary"
            size={'small'}
            disabled={link?.last_used != null}
            onClick={() => {
              connectIntegration()
            }}
            sx={{
              '& > span': { marginRight: '0px', marginLeft: '0px' },
              minWidth: 'unset',
              padding: '4px',
            }}
          />
        ) : (
          <Button
            startIcon={<AddSvg color="inherit" />}
            color="primary"
            size={mdUp ? 'medium' : 'small'}
            onClick={() => {
              connectIntegration()
            }}
            disabled={link?.last_used != null}
          >
            {t('common:button.connect')}
          </Button>
        )}
      </Box>
    </Box>
  )
}

const FakeApps = [
  {
    name: 'Dentbird Solutions',
    logoUri: '/logos/dentbird.png',
  },
  {
    name: 'CADflow',
    logoUri: '/logos/cadflow.png',
  },
  {
    name: 'Dentscape',
    logoUri: '/logos/dentscape.png',
  },
  {
    name: 'Evident',
    logoUri: '/logos/evident.png',
  },
  {
    name: '3shape',
    logoUri: '/logos/3shape.png',
  },
]

const FakeApp = ({ name, logoUri }: { name: string; logoUri: string }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const halfSmDown = useMediaQuery(
    theme.breakpoints.down((theme.breakpoints.values['sm'] * 2) / 3),
  )
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))
  const size = mdUp ? 160 : smDown ? 80 : 130

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        [theme.breakpoints.up('lg')]: {
          gap: '32px',
        },
        [theme.breakpoints.down('lg')]: {
          gap: '24px',
        },
        [theme.breakpoints.down('md')]: {
          gap: '16px',
        },
        [theme.breakpoints.down('sm')]: {
          gap: '15px',
        },
        flexShrink: 1,
        bgcolor: (theme) => theme.surface.normal,
        borderRadius: '0.5em',
        padding: '15px',
      }}
    >
      <Avatar
        alt={name}
        variant="rounded"
        src={logoUri}
        sx={{
          width: `${size}px`,
          height: `${size}px`,
          '& > .MuiAvatar-img': {
            width: `${size}px`,
            height: `${size}px`,
            objectFit: 'contain',
          },
        }}
      ></Avatar>
      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: `${size}px`,
            flexGrow: 1,
          }}
        >
          <Link
            component="a"
            href={'#'}
            underline="hover"
            sx={{
              fontWeight: (theme: Theme) => theme.typography.fontWeightBold,
              fontSize: '0.8rem',
            }}
          >
            {name}
          </Link>
          <Typography
            variant={'body2'}
            sx={{
              flexShrink: 1,
              overflow: 'hidden',
              fontSize: '0.8rem',
              display: halfSmDown ? 'none' : 'block',
            }}
          >Coming soon</Typography>{ /*eslint-disable-line */ }
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {smDown ? (
          <Button
            startIcon={<AddSvg color="inherit" />}
            color="primary"
            size={'small'}
            disabled
            sx={{
              '& > span': { marginRight: '0px', marginLeft: '0px' },
              minWidth: 'unset',
              padding: '4px',
            }}
          />
        ) : (
          <Button
            startIcon={<AddSvg color="inherit" />}
            color="primary"
            size={mdUp ? 'medium' : 'small'}
            disabled
          >
            {t('common:button.connect')}
          </Button>
        )}
      </Box>
    </Box>
  )
}

const EMPTY_INTEGRATION_ARRAY: IntegrationReadPublic[] = []

const generateIntegrationsArgs = ({
  searchValue,
}: {
  searchValue: string
}) => ({
  query:
    searchValue !== ''
      ? `name:"*${searchValue}*" AND description:"*${searchValue}*"`
      : undefined,
})

const Grid = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const searchValue = useSelector((state: RootState) => state.apps.searchApps)
  const { data, error, isLoading } =
    useGetIntegrationsApiV1IntegrationsGetQuery(
      generateIntegrationsArgs({ searchValue }),
      {
        selectFromResult: ({ data, error, isLoading }) => ({
          data: (data?.content ?? EMPTY_INTEGRATION_ARRAY).map(
            (integration) => integration.client_id,
          ),
          error,
          isLoading,
        }),
      },
    )
  return error ? (
    <>{localizeError(t, ensureError(error))}</>
  ) : isLoading ? (
    <LinearProgress />
  ) : data ? (
    <Box
      component="div"
      sx={{
        overflow: 'auto',
      }}
    >
      <Box
        component="div"
        sx={{
          display: 'grid',
          [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(auto-fill, minmax(320, 1fr));',
          },
          [theme.breakpoints.between('md', 'sm')]: {
            gridTemplateColumns: 'repeat(auto-fill, minmax(420px, 1fr));',
          },
          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(auto-fill, minmax(620px, 1fr));',
          },
          gap: '1em',
        }}
      >
        {data.map((client_id) => (
          <App key={client_id} client_id={client_id} />
        ))}
      </Box>
      <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
      <Typography variant='h1' sx={{ marginTop: '10px', marginBottom: '10px'}}>Coming soon</Typography>{/* eslint-disable-line */}
      <Box
        component="div"
        sx={{
          display: 'grid',
          [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(auto-fill, minmax(320, 1fr));',
          },
          [theme.breakpoints.between('md', 'sm')]: {
            gridTemplateColumns: 'repeat(auto-fill, minmax(420px, 1fr));',
          },
          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(auto-fill, minmax(620px, 1fr));',
          },
          gap: '1em',
        }}
      >
        {FakeApps.map((data) => (
          <FakeApp key={data.name} name={data.name} logoUri={data.logoUri} />
        ))}
      </Box>
    </Box>
  ) : (
    <>{null}</>
  )
}

const AppsActionBar = () => {
  const { t } = useTranslation('apps')
  const searchValue = useSelector((state: RootState) => state.apps.searchApps)
  const setSearchValue = (state: string) => store.dispatch(setSearchApps(state))
  return (
    <Box
      component="div"
      sx={{
        marginBottom: '1em',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignContent: 'center',
        width: '100%',
        gap: '6px',
      }}
    >
      <SearchField
        placeholder={t('label.search')}
        value={searchValue}
        onChange={setSearchValue}
      />
    </Box>
  )
}

const AppsGrid = () => {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
    >
      <AppsActionBar />
      <Grid />
    </Box>
  )
}

export { AppsGrid }
