import { PartStatus } from '../constants/partStatus'
import {
  addTagTypes,
  baseClientApi as api,
  DownloadJobApiV1JobsJobIdUploadGetApiArg,
  DownloadJobApiV1JobsJobIdUploadGetApiResponse,
  DownloadPartApiV1PartsPartIdUploadedGetApiArg,
  DownloadPartApiV1PartsPartIdUploadedGetApiResponse,
  GetDeviceStatusApiV1DevicesSerialNumberStatusGetApiArg,
  GetDeviceStatusApiV1DevicesSerialNumberStatusGetApiResponse,
  GetJobsApiV1JobsGetApiArg,
  GetJobsApiV1JobsGetApiResponse,
  GetOrganisationBillingSessionApiV1OrganisationsOrgIdGetApiArg,
  GetOrganisationBillingSessionApiV1OrganisationsOrgIdGetApiResponse,
  GetPartThumbnailApiArg,
  GetPartThumbnailApiResponse,
  JobReadPublic,
} from './baseClientApi'
import { emptyApi } from './emptyApi'

const matchJobId = RegExp(/job_id:"?(\d+)"?/, 'g')
const matchStatus = RegExp(/status:"?(\d+)"?/, 'g')

const CreateTags = <T, V>(
  tag: V,
  result: T | T[] | undefined,
  selectId: (item: T) => string,
  isList?: boolean,
  additional?: string[],
) =>
  result
    ? [
        ...(Array.isArray(result)
          ? result.map((item) => ({
              type: tag,
              id: selectId(item),
            }))
          : [
              {
                type: tag,
                id: selectId(result),
              },
            ]),
        ...(isList ? [{ type: tag, id: 'LIST' }] : []),
        ...(additional ? additional.map((id) => ({ type: tag, id: id })) : []),
      ]
    : [tag]

const clientApi = api.enhanceEndpoints({
  addTagTypes: ['users-meta', 'users-bfp', 'users-reset', 'user-role'],
  endpoints: {
    getRolesApiV1UsersRolesGet: {
      providesTags: ['users-meta'],
    },
    removeBfpUserApiV1UsersUserIdBlockBfpDelete: {
      invalidatesTags: ['users-bfp'],
    },
    getBfpUserApiV1UsersUserIdBlockBfpGet: {
      providesTags: ['users-bfp'],
    },
    resetUserApiV1UsersUserIdResetPut: {
      invalidatesTags: ['users-reset'],
    },
    getUserRolesApiV1UsersUserIdRolesGet: {
      providesTags: (result, error, args) => [
        { type: 'user-role', id: args.userId },
      ],
    },
    removeRoleApiV1UsersUserIdRolesRoleDelete: {
      invalidatesTags: (result, error, args) => [
        { type: 'user-role', id: args.userId },
      ],
    },
    addRoleApiV1UsersUserIdRolesRolePatch: {
      invalidatesTags: (result, error, args) => [
        { type: 'user-role', id: args.userId },
      ],
    },
    signContextApiV1SignContextPatch: {
      invalidatesTags: undefined,
    },
    getDevicesApiV1DevicesGet: {
      providesTags: (result, error, arg) =>
        CreateTags('devices', result?.content, (item) => item.thing_name, true),
    },
    getDevicesWithStatusApiV1DevicesStatusGet: {
      providesTags: (result, error, arg) =>
        CreateTags('devices', result?.content, (item) => item.thing_name, true),
    },
    getDeviceApiV1DevicesSerialNumberGet: {
      providesTags: (result, error, arg) =>
        CreateTags('devices', result, (item) => item.thing_name),
    },
    getDeviceStatusApiV1DevicesSerialNumberStatusGet: {
      providesTags: (result, error, arg) =>
        CreateTags('devices', result, (item) => item.thing_name),
    },
    getDeviceBuildExtentsApiV1DevicesSerialNumberBuildExtentsGet: {
      providesTags: [],
    },
    getJobsApiV1JobsGet: {
      providesTags: (result, error, arg) => {
        // add status cache tags if jobs are being filtered by status
        let statuses: string[] = []
        if (arg.query?.includes('status:')) {
          statuses = [...arg.query.matchAll(matchStatus)].map(
            (match) => match[1],
          )
        }

        const additionalTags = statuses.map((status) => `status-${status}`)

        // add CREATE cache tag when filtering for status 0 aka new jobs
        if (statuses.includes('0')) additionalTags.push('CREATE')

        return CreateTags(
          'jobs',
          result?.content,
          (item) => item.id.toString(),
          statuses.length === 0, // only append list tag if there are no status tags
          additionalTags,
        )
      },
    },
    getJobApiV1JobsJobIdGet: {
      providesTags: (result, error, arg) =>
        CreateTags('jobs', result, (item) => item.id.toString()),
    },
    getPartApiV1PartsPartIdGet: {
      providesTags: (result, error, arg) =>
        CreateTags('parts', result, (item) => item.id.toString()),
    },
    getPartsApiV1PartsGet: {
      providesTags: (result, error, arg) => {
        // return job cache tags if parts are tied to job
        if (arg.query?.includes('job_id:')) {
          const ids = [...arg.query.matchAll(matchJobId)].map(
            (match) => match[1],
          )
          if (ids.length !== 0) {
            return CreateTags('jobs', ids, (item) => item)
          }
        }

        // add status cache tags if parts are being filtered by status
        let statuses: string[] = []
        if (arg.query?.includes('status:')) {
          statuses = [...arg.query.matchAll(matchStatus)].map(
            (match) => match[1],
          )
        }

        const additionalTags = statuses.map((status) => `status-${status}`)

        return CreateTags(
          'parts',
          result?.content,
          (item) => item.id.toString(),
          statuses.length === 0, // only append list tag if there are no status tags
          additionalTags,
        )
      },
    },
    getOrganisationsApiV1OrganisationsGet: {
      providesTags: (result, error, arg) =>
        CreateTags(
          'organisations',
          result?.content,
          (item) => item.id.toString(),
          true,
        ),
    },
    getOrganisationApiV1OrganisationsOrgIdGet: {
      providesTags: (result, error, arg) =>
        CreateTags('organisations', result, (item) => item.id.toString()),
    },
    getOrganisationSubscriptionApiV1OrganisationsOrgIdGet: {
      providesTags: (result, error, arg) =>
        CreateTags('organisations', arg.orgId, (item) => item.toString()),
    },
    getTemplatesApiV1TemplatesGet: {
      providesTags: (result, error, arg) =>
        [
          PartStatus.toAccept,
          PartStatus.accepted,
          PartStatus.awaitingPlacement,
          PartStatus.processing,
          PartStatus.placing,
          PartStatus.placed,
          PartStatus.failed,
        ]
          .map((status) => ({ type: 'parts', id: `status-${status}` }) as any)
          .concat(
            CreateTags(
              'templates',
              result?.content,
              (item) => item.id.toString(),
              true,
            ),
          ),
    },
    getTemplateHistoryApiV1TemplatesTemplateIdHistoryGet: {
      providesTags: (result, error, arg) =>
        CreateTags(
          'templates',
          result?.content,
          (item) => item.id.toString(),
          true,
        ),
    },
    getTemplateApiV1TemplatesTemplateIdGet: {
      providesTags: (result, error, arg) =>
        CreateTags('templates', arg.templateId, (item) => item.toString()),
    },
  },
})

const downloadMutationApi = emptyApi
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      downloadPartApiV1PartsPartIdUploadedGet: build.mutation<
        DownloadPartApiV1PartsPartIdUploadedGetApiResponse,
        DownloadPartApiV1PartsPartIdUploadedGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/parts/${queryArg.partId}/uploaded`,
        }),
      }),
      downloadJobApiV1JobsJobIdUploadGet: build.mutation<
        DownloadJobApiV1JobsJobIdUploadGetApiResponse,
        DownloadJobApiV1JobsJobIdUploadGetApiArg
      >({
        query: (queryArg) => ({ url: `/api/v1/jobs/${queryArg.jobId}/upload` }),
      }),
      getDeviceWithStatusGet: build.mutation<
        GetDeviceStatusApiV1DevicesSerialNumberStatusGetApiResponse,
        GetDeviceStatusApiV1DevicesSerialNumberStatusGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/devices/${queryArg.serialNumber}/status`,
        }),
      }),
      getOrganisationBillingSessionApiV1OrganisationsOrgIdGet: build.mutation<
        GetOrganisationBillingSessionApiV1OrganisationsOrgIdGetApiResponse,
        GetOrganisationBillingSessionApiV1OrganisationsOrgIdGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/organisations/${queryArg.orgId}/billing/session`,
        }),
      }),
      getPartApiV1PartsPartIdThumbnailGet: build.mutation<
        GetPartThumbnailApiResponse,
        GetPartThumbnailApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/parts/${queryArg.partId}/thumbnail`,
        }),
      }),
      getJobsByDevice: build.query<
        {
          byDevice: { [id: string]: JobReadPublic[] }
          ids: number[]
        },
        GetJobsApiV1JobsGetApiArg
      >({
        query: (queryArg) => ({
          url: `/api/v1/jobs`,
          params: {
            page: queryArg.page,
            per_page: queryArg.perPage,
            query: queryArg.query,
            sorting: queryArg.sorting,
          },
        }),
        transformResponse: (response: GetJobsApiV1JobsGetApiResponse) => ({
          byDevice: (response?.content ?? []).reduce(
            (initial, job) => {
              if (initial[job.printer_serial] === undefined)
                initial[job.printer_serial] = [] as JobReadPublic[]
              initial[job.printer_serial].push(job)
              return initial
            },
            {} as { [id: string]: JobReadPublic[] },
          ),
          ids: (response?.content ?? []).map((job) => job.id),
        }),
        providesTags: (result, error, arg) => {
          // add status cache tags if jobs are being filtered by status
          let statuses: string[] = []
          if (arg.query?.includes('status:')) {
            statuses = [...arg.query.matchAll(matchStatus)].map(
              (match) => match[1],
            )
          }

          const additionalTags = statuses.map((status) => `status-${status}`)

          // add CREATE cache tag when filtering for status 0 aka new jobs
          if (statuses.includes('0')) additionalTags.push('CREATE')

          return CreateTags(
            'jobs',
            result?.ids,
            (item) => item.toString(),
            statuses.length === 0, // only append list tag if there are no status tags
            additionalTags,
          )
        },
      }),
    }),

    overrideExisting: true,
  })

enum JobStatus {
  CANDIDATE = 0,
  CREATED = 1,
  PROCESSING = 2,
  TO_ACCEPT = 3,
  PREPARING_TO_SEND = 4,
  PRINT_PENDING = 5,
  PRINTING = 6,
  PRINTED = 7,
  FAILED = 8,
  REJECTED = 9,
  OFFLINE = 10,
  CANCELLED = 11,
}

export const REFETCH_TIMEOUT = 4000

export const {
  useDownloadPartApiV1PartsPartIdUploadedGetMutation,
  useDownloadJobApiV1JobsJobIdUploadGetMutation,
  useGetDeviceWithStatusGetMutation,
  useGetOrganisationBillingSessionApiV1OrganisationsOrgIdGetMutation,
  useGetPartApiV1PartsPartIdThumbnailGetMutation,
  useGetJobsByDeviceQuery,
} = downloadMutationApi

export * from './baseClientApi'
export { clientApi, JobStatus }
