import { useEffect, useState } from 'react'
import { useUser } from '../../hooks/user'

const setHTMLInputElementValue = (input: HTMLInputElement, value: string) => {
  const prevValue = input.value
  input.value = value

  const tracker = (input as any)?._valueTracker as any
  if (tracker) tracker?.setValue(prevValue)
  input.dispatchEvent(new Event('change', { bubbles: true }))
}

export const ShowJiraServiceDeskWidget = () => {
  const targetNode = document.getElementById('jsd-widget')
  if (targetNode == null) return

  const iframe = targetNode as HTMLIFrameElement
  const helpButton =
    iframe?.contentWindow?.document?.getElementById('help-button')
  if (helpButton == null) return

  helpButton.click()
}

export const JiraServiceDeskWidget = ({ apiKey }: { apiKey: string }) => {
  useEffect(() => {
    // load the jsd widget
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.setAttribute('data-jsd-embedded', '')
    script.setAttribute('data-key', apiKey)
    script.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com')
    script.src = 'https://jsd-widget.atlassian.com/assets/embed.js'
    script.addEventListener('load', () => {
      window.document.dispatchEvent(
        new Event('DOMContentLoaded', {
          bubbles: true,
          cancelable: true,
        }),
      )
    })
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
      const widget = document.getElementById('jsd-widget')
      widget?.parentElement?.removeChild(widget)
    }
  }, [apiKey])

  useEffect(() => {
    // observes changes in the jsd iframe
    const iframeObserver = new MutationObserver(() => {
      const targetNode = document.getElementById('jsd-widget')
      if (targetNode == null) return

      const iframe = targetNode as HTMLIFrameElement
      const helpButton =
        iframe?.contentWindow?.document?.getElementById('help-button')

      targetNode.style.visibility = helpButton == null ? 'visible' : 'hidden'

      if (helpButton == null) {
        return
      }

      helpButton.style.visibility = 'hidden'
    })

    // observes changes in body
    // used to attach an observer to the jsd iframe when it loads
    const bodyObserver = new MutationObserver(() => {
      const targetNode = document.getElementById('jsd-widget')
      const iframe = targetNode as HTMLIFrameElement | null | undefined

      iframeObserver.disconnect()

      if (iframe)
        iframeObserver.observe(iframe, {
          attributes: true,
          childList: false,
          subtree: false,
        })
      if (iframe?.contentWindow?.document?.body)
        iframeObserver.observe(iframe?.contentWindow?.document?.body, {
          attributes: true,
          childList: true,
          subtree: false,
        })
    })

    bodyObserver.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: false,
    })

    return () => {
      bodyObserver.disconnect()
      iframeObserver.disconnect()
    }
  }, [])

  return <></>
}

export const JiraServiceDeskWidgetController = () => {
  const { data } = useUser()
  const email = data?.email

  useEffect(() => {
    if (email == null) return

    const observer = new MutationObserver(() => {
      const targetNode = document.getElementById('jsd-widget')
      if (targetNode == null) return

      const iframe = targetNode as HTMLIFrameElement
      const emailElement =
        iframe?.contentWindow?.document?.getElementById('email')

      if (emailElement == null) return

      setHTMLInputElementValue(emailElement as HTMLInputElement, email)
    })

    const targetNode = document.getElementById('jsd-widget')
    const iframe = targetNode as HTMLIFrameElement | null | undefined
    observer.observe(iframe ?? document.body, {
      attributes: true,
      childList: true,
      subtree: false,
    })

    return () => observer.disconnect()
  }, [email])

  return <></>
}

export const useCanOpenJSD = () => {
  const [canOpen, setCanOpen] = useState(false)
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const targetNode = document.getElementById('jsd-widget')
      if (targetNode == null) return

      const iframe = targetNode as HTMLIFrameElement
      const helpButton =
        iframe?.contentWindow?.document?.getElementById('help-button')

      setCanOpen(helpButton != null)
    })

    observer.observe(document.getElementById('jsd-widget') ?? document.body, {
      attributes: true,
      childList: true,
      subtree: false,
    })

    return () => observer.disconnect()
  }, [])

  return canOpen
}
